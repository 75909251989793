import React from "react";
import { Link } from "react-router-dom";

const BlogPostComponent = ({ post }) => {
  return (
    <article>
      <div className=" tag-container">
        {post.categories.length > 0 &&
          post.categories.map((category) => {
            return (
              <div key={category} className=" tag-content">
                {category}
              </div>
            );
          })}
      </div>

      <h2 className="title">
        <Link target="_blank" rel="noopener noreferrer" to={post.link}>
          {post.title}
        </Link>
      </h2>

      <div className="d-flex align-items-center justify-content-between">
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={post.link}
          className="read-more"
        >
          Read More {`->`}
        </Link>
        <p className="time-to-read">{post.timeToRead}</p>
      </div>
    </article>
  );
};

export default BlogPostComponent;
