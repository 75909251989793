import { BiListUl, BiUser } from "react-icons/bi";
import { FaUserTie } from "react-icons/fa6";
import { MdDarkMode, MdOutlineLightMode } from "react-icons/md";
import { Link } from "react-router-dom";

const menuList = [
  {
    title: "Latest",
    id: "about",
    link: "/#about",
    isLink: false,
    isTarget: false,
  },
  {
    title: "Posts",
    id: "post",
    link: "/",
    chidrens: [
      {
        id: "userList",
        title: "List",
        link: "/user-list",
        ListIcon: BiListUl,
      },
    ],

    ListIcon: BiUser,
    isLink: false,
    isTarget: false,
  },
];

const Header = ({ isDarkMode = false, handleThemeClick }) => {
  const ICON_SIZE = 25;

  return (
    <div className="nav-main-container">
      <div className="container">
        <div className="nav-container">
          <div className="nav-items justify-content-start">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={"https://kawaljain.com/"}
              className="nav-item-text"
            >
              <FaUserTie size={ICON_SIZE} />
            </Link>
            {/* {menuList.map((list) => {
              return (
                <>
                  {list.chidrens && list.chidrens.length > 0 ? (
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {list.title}
                      </button>

                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {list.chidrens.map((child) => {
                          <div class="dropdown-item" href="#">
                            {child.title}
                          </div>;
                        })}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })} */}
          </div>
          {/* <div className="nav-logo-container ">AnviKJs</div> */}

          <div className="nav-items justify-content-end">
            <div className="nav-item-text" onClick={handleThemeClick}>
              {isDarkMode ? (
                <MdDarkMode size={ICON_SIZE} />
              ) : (
                <MdOutlineLightMode size={ICON_SIZE} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
