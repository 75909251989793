import BlogPostComponent from "../components/BlogPost";

const data = [
  {
    id: "markdown",
    categories: ["Javascript", "React"],
    title: "Building My Own Markdown Parser: A Developer’s Journey 🚀",
    link: "https://javascript.plainenglish.io/building-my-own-markdown-parser-a-developers-journey-2afda1df8f39",
    date: "Oct 20, 2024",
    timeToRead: "2 min",
  },
  {
    id: "asynchronousBehaviour",
    categories: ["Javascript"],
    link: "https://javascript.plainenglish.io/javascript-interview-question-explanation-asynchronous-behaviour-2588082f7b61",
    title: " Interview Question Explanation -Asynchronous Behaviour",
    date: "Oct 15, 2024",
    timeToRead: "4 min",
  },
  {
    id: "objectProperties",
    categories: ["Javascript"],
    link: "https://javascript.plainenglish.io/javascript-interview-question-explanation-object-properties-7c25f060dbd3",
    title: " Interview Question Explanation- Object Properties",
    date: "Oct 8, 2024",
    timeToRead: "3 min",
  },
  {
    id: "react-infinite-scroll-calendar",
    categories: ["Javascript", "Npm", "React"],
    title: "React Infinite Scroll Calendar",
    link: "https://javascript.plainenglish.io/react-infinite-scroll-calendar-d1d7996c1e16",
    timeToRead: "4 min",
    date: "Nov 21, 2024",
  },
  {
    id: "build-simple-efficient-and-reusable-components-for-react-npm-package",
    categories: ["React", "Npm"],
    title: "NPM React Package with Rollup",
    link: "https://javascript.plainenglish.io/build-simple-efficient-and-reusable-components-for-react-npm-packages-ad40d5d86e75",
    date: "Oct 19, 2024",
    timeToRead: "5 min",
  },
  {
    id: "javascript-singleton-design-patterns",
    link: "https://kawaljain.medium.com/javascript-singleton-design-patterns-8b11c75a3b8e",
    categories: ["Javascript", "Design Pattern"],
    title: " Singleton Design Patterns",
    date: "Oct 8, 2024",
    timeToRead: "1 min",
  },

  // {
  //   id: "",
  //   category: "Javascript",
  //   title: "",
  //   date: "",
  //   timeToRead: "",
  // },
];
function Home() {
  return (
    <>
      {/* <SectionHeader title="Explore Learn Build 🚀" description="AnviKJs" /> */}
      <section className="blog  blog-posts section section-wrapper ">
        <div className="container">
          <div className="row gy-4">
            {data.map((blog, index) => {
              return (
                <div className="col-lg-4" key={index}>
                  <BlogPostComponent post={blog} />
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* <section id="blog-pagination" className="blog-pagination section">
        <div className="container">
          <div className="d-flex justify-content-center">
            <Pagniation />
          </div>
        </div>
      </section> */}
    </>
  );
}

export default Home;
