import { BrowserRouter } from "react-router-dom";

import Navbar from "./components/Navbar";
import Application from "./Application";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./assets/css/global.css";
import { useState } from "react";

// darkTheme
function App() {
  const [isDarkMode, setIsDarkMode] = useState(true);

  const handleThemeClick = () => {
    setIsDarkMode(!isDarkMode);
  };
  // MdDarkMode
  return (
    <BrowserRouter>
      <div
        className={` ${isDarkMode ? "dark-background" : "light-background"}`}
      >
        <Navbar isDarkMode={isDarkMode} handleThemeClick={handleThemeClick} />
        <div className="main-container">
          <Application />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
