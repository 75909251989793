import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import BlogDetail from "./pages/Post";
import About from "./pages/About";

function Application() {
  return (
    <>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route index path="/about" element={<About />} />
        <Route index path="/post" element={<BlogDetail />} />
      </Routes>
    </>
  );
}

export default Application;
