import SectionHeader from "../components/SectionHeader";

export default function About() {
  return (
    <>
      <section id="about" className="about section">
        'About'
      </section>
    </>
  );
}
